import * as React from 'react';
import './Applications.css';

const Applications = () => {
  return (
    <div className="we-do-body">
      <div className="header">
        <h2>Cloud Migration and Operations</h2>
        <p>We offer migration services, which can reduce recurring costs for power, space, cooling, and hardware recapitalizations, while improving agility across the enterprise by moving data, applications, or other business systems to virtual platforms. This enables innovation at the speed required in today’s mission environments.</p>
        <div className="app">
          {/* <img src={customApp} alt="custom app" /> */}
        </div>
      </div>
    </div>
  )
};

export default Applications;