import * as React from 'react';
import C3Chart from 'react-c3js';
import 'c3/c3.css';
import './DataVis.css';

const DataVis = () => {

  return (
    <div className="we-do-body">
      <div className="data-top">
        <div className="header">
          <h2>DevSecOps</h2>
          <p>We offer DevSecOps models and platforms, that let our customers develop and deploy at the speed of Mission. Our approach incorporates quality assurance and security teams from the beginning, ensuring seamless integration with the development and operations efforts throughout the application’s lifecycle.</p>
        </div>
      </div>
    </div>
  );
};

export default DataVis;