import * as React from 'react';
// import './Applications.css';

const Enterprise = () => {
  return (
    <div className="we-do-body">
      <div className="header">
        <h2>Enterprise IT Management</h2>
        <p>We offer services that ensure one’s mission application is performing as intended and ready to face today’s ever-growing challenges.</p>
        <div className="app">
          {/* <img src={customApp} alt="custom app" /> */}
        </div>
      </div>
    </div>
  )
};

export default Enterprise;