import * as React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Home from '../Home/Home';
import WeDo from '../WeDo/WeDo';
import About from '../About/About';
import Contact from '../Contact/Contact';
import JDB from '../images/JDB_Transparency.png';
import './Nav.css';
  
const Nav = () => {
  return (
    <Router>
      <div>
        <nav>
          <div className="Logo">
            <Link to="/"><img src={JDB} alt="JDB Industries"/></Link>
          </div>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/what-we-do">Services</Link>
            </li>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <a href="https://jobs.gusto.com/boards/jdb-industries-llc-b3b6ea05-5de9-49d4-b46c-393e36bcf23a" target="_">Careers</a>
            </li>
            {/* <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <Link to="/about">About Us</Link>
            </li> */}
          </ul>
        </nav>
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/what-we-do">
            <WeDo />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default Nav;