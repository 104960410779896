import * as React from 'react';
import './Photos.css';


const Photos = () => {
  return (
    <div className="we-do-body">
      <div className="header">
        <h2>Full Stack Development</h2>
        <p>We offer cloud-native developers and solutions, which use microservices, allowing our customers to jump start an MVP or deliver a production ready product.</p>
        <div className="photos">
          {/* <img src={River} alt="photo1"/>
          <img src={Union} alt="photo2"/>
          <img src={WWII} alt="photo6"/>
          <img src={Pond} alt="photo3"/>
          <img src={Cathedral} alt="photo4"/>
          <img src={Lincoln} alt="photo5"/> */}
        </div>
      </div>
    </div>
  )
};

export default Photos;