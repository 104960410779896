import * as React from 'react';
import './Home.css';

const Home = () => {
  return (
    <div className="home">
      <h1>JDB Industries</h1>
      <h5><em>Your Mission is our Passion</em></h5>
      <div className="home-background">
      
      </div>
    </div>
  );
};

export default Home;
