import * as React from 'react';
import './ECommerce.css';

const ECommerce = () => {
  return (
    <div className="we-do-body">
      <div className="header">
        <h2>Cyber Security</h2>
        <p>We offer services, tools, and resources to ensure the Confidentiality, Integrity, and Availability (CIA) of mission information. We have a methodology to deploy these resources in the most cost-effective manner, while still protecting against internal and external threats, infrastructure outages, and natural disasters. </p>
        <div className="ecom">
          {/* <img src={Ecommerce} alt="ecommerce"/> */}
          
        </div>
      </div>
    </div>
  )
};

export default ECommerce;