import * as React from 'react';
import JDBLogo from '../images/JDB_Transparency.png';
import './About.css';

const About = () => {
  return (
  <div>
    <img src={JDBLogo} className="logo" />
    <h5><em>Your Mission is our Passion</em></h5>
    <p className="main-about">Although our name is broad, we are laser focused on Information Technology Services.  We specialize in empowering organizations to create, manage, and optimize their applications and infrastructure. We understand and educate our customers on the need for DevSecOps, which enables modern day development, allows for the integration of security best practices and tools, while ensuring a focus on security through the entire IT lifecycle.  All of this is to ensure excellence in operations. We believe in, and tout, the benefits of the DevSecOps paradigm, which builds a foundation for automation, the framework for blue green deployments, all while reducing the costs of ones IT services and security vulnerabilities.  At JDB Industries, we believe every industry can benefit from our proven DevSecOps methodology and strive to use our passion to better enable your mission.</p>
  </div>
  );
};

export default About;