import React from 'react';
import Nav from '../Nav/Nav';
import './App.css';
import Footer from '../Footer/Footer';

const App = () => {
  return (
    <div className="App">
      <div className="App-header">
        <Nav />
        {/* <div className="App-footer">
        <Footer />
      </div> */}
      </div>
    </div>
  );
}

export default App;
