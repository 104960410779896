import * as React from 'react';
import ReactPageScroller from "react-page-scroller";
import { Button } from '@material-ui/core';
import './WeDo.css';
import DataVis from './DevSecOps/DevSecOps';
import Photos from './Development/Development';
import ECommerce from './Security/Security';
import Applications from './Cloud/Applications';
import Consulting from './Consulting/Consulting';
import Enterprise from './Enterprise/Enterprise';
import JDBLogo from '../images/JDB_Transparency.png';

export default class WeDo extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentPage: null };
  }

  handlePageChange = number => {
    this.setState({ currentPage: number });
  };

  render() {

    return (
      <div className="we-do-page">
        <ReactPageScroller
          pageOnChange={this.handlePageChange}
          customPageNumber={this.state.currentPage}
        >
          <div className="we-do-body"> 
            <img src={JDBLogo} className="logo" />
            <p className="header" style={{marginTop: '1rem', alignSelf: 'center', textAlign: 'center' }}>
            At JDB Industries, we believe every industry can benefit from our proven DevSecOps methodology and strive to use our passion to better enable your mission.   <br></br> We have broken our DevSecOps methodology into several pillars and offer these services to our customers. 
            </p>
          </div>
          <Consulting />
          <DataVis />
          <Photos />
          <ECommerce />
          <Applications />
          
          <Enterprise />
        </ReactPageScroller>
        <div className="page-tracker">
          <div className="inner-page-tracker">
            <Button
              onClick={() => {
                this.handlePageChange(0);
              }}
            >
              <em>Overview</em>
            </Button>
            <Button
              onClick={() => {
                this.handlePageChange(1);
              }}
            >
              <em>Consulting and Program Management</em>
            </Button>
            <Button
              onClick={() => {
                this.handlePageChange(2);
              }}
            >
              <em>DevSecOps</em>
            </Button>
            <Button
              onClick={() => {
                this.handlePageChange(3);
              }}
            >
              <em>Full Stack Development</em>
            </Button>
            <Button
              onClick={() => {
                this.handlePageChange(4);
              }}
            >
              <em>Cyber Security</em>
            </Button>
            <Button
              onClick={() => {
                this.handlePageChange(5);
              }}
            >
              <em>Cloud Migration and Operations</em>
            </Button>
            <Button
              onClick={() => {
                this.handlePageChange(6);
              }}
            >
              <em>Enterprise IT Management</em>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
