import * as React from 'react';
import './Consulting.css';

const Consulting = () => {
  return (
    <div className="we-do-body">
      <div className="header">
        <h2>Consulting and Program Management</h2>
        <p>We offer subject matter experts that have a proven track record of integrating and overseeing a collection of projects that enable full stack development, multi-layer hardware integration, network upgrades to allow modern day technologies, like zero trust networks, optimizing cloud environments and enabling virtualization rollouts.</p>
        <div className="consulting">
          {/* <img src={Consult} alt="consulting" /> */}
        </div>
      </div>
    </div>
  )
};

export default Consulting;